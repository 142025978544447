import PropTypes from 'prop-types';
import DynamicAntdTheme from 'dynamic-antd-theme';
import Header from './Header';
import {Button, Layout, Menu} from 'antd';
import {
  ContainerOutlined,
  DesktopOutlined, MenuFoldOutlined, MenuUnfoldOutlined,
  PieChartOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useRouter} from "next/router";
const { Content, Footer, Sider } = Layout;

const items = [
  getItem('收款钱包', '1', <PieChartOutlined />),
  getItem('提现钱包', '2', <DesktopOutlined />),
  getItem('谷歌验证', '3', <ContainerOutlined />),
];

function getItem(label, key, icon, children) {
  return <Menu.Item icon={icon} key={key} label={label}>{label}</Menu.Item>;
}

const menuMap = {
  1:"/home",
  2:"/withdraw",
  3:"/googleVaild"
};

const routerMap = {
  "/home":"1",
  "/withdraw":"2",
  "/googleVaild":"3"
};

function AppLayout({ children }) {

  const router = useRouter();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(["1"]);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const gotoPage = (page) =>{
    router.push(menuMap[page.key]);
    setSelectedKey([page.key]);
  };

  useEffect(()=>{
    setSelectedKey([routerMap[router.pathname]]);
    let session = sessionStorage.getItem("token");
    if (!session && router.pathname !== "/"){
      router.push("/");
    }
  }, []);

  return (
    <>
      <style jsx>{`
        .content-container {
          display: flex;
          background-color: #fff;
          margin-top: 60px;
          min-height: 100vh;
          padding: 12px 14px;
          width: 100vw !important;
          overflow: hidden;
        }
      `}</style>
      <Header />
      <div className='content-container'>
        {
          router.pathname !== "/" ? <div>
            <Button
              type={"primary"}
              onClick={toggleCollapsed}
              style={{
                marginBottom: 16,
                width:50
              }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <Menu
              onClick={gotoPage}
              style={{ width: collapsed ? 50 : 200, color:"#000" }}
              defaultSelectedKeys={['1']}
              mode={"inline"}
              inlineCollapsed={collapsed}
              selectedKeys={selectedKey}
            >
              {
                items.map(item=>item)
              }
            </Menu>
          </div> : null
        }
        {children}
      </div>
    </>
  );
}
export default AppLayout;
AppLayout.propTypes = {
  children: PropTypes.any
};

AppLayout.defaultProps = {
  children: null
};
