import Head from 'next/head';
import { wrapper } from '../redux/store';
import '../../assets/global.css';
import AppLayout from "../components/Layout";

function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta charSet='utf-8' />
        <title>AIS后台管理</title>
        <link rel='shortcut icon' href='/favicon.ico' type='image/ico'/>
      </Head>
      <AppLayout>
        <Component {...pageProps} />
      </AppLayout>
    </>
  );
}


export default wrapper.withRedux(App);
